// /auth/dialog/authorize

'use strict';

angular.module('enervexSalesappApp').controller('RegistrationCtrl', function($scope, Registration, $stateParams, $cookies, $http, Flash, Auth, $state) {
	$scope.registration = null;
	$scope.completed = false;
	$scope.approved = false;
	$scope.rejected = false;


	console.log("params "+JSON.stringify($stateParams))
	Registration.get({
		id: $stateParams.id
	}).$promise.then(function(registration) {
		console.log("you have "+JSON.stringify(registration))
		$scope.registration = registration;
	}).catch(function(e){
		console.log(e)
	})
	$scope.approve = function(registration) {
		Registration.update({
			id:registration._id
		}, {
			status: "accepted"
		}).$promise.then(function(res){
			if (res.token){
				Auth._login(res);
				$scope.completed = true;
				
				$scope.approved = true;
				var message = '<strong>Success!</strong> You successfully confirmed your account, you are now logged in.  We are now redirecting you to the dashboard';
				Flash.create('success', message, 0, {}, true);
				setTimeout(function() {
					if ($stateParams.redirect) {
						window.location.href=$stateParams.redirect;
					} else {
						$state.go("accounts", {
							reload:true
						});
					}
				},2000)
			} else {
				var message = '<strong>ERROR!</strong> Could not log you in.';
				Flash.create('error', message, 0, {}, true);
			}
		}).catch(function(err){
			console.log("Error")
		})
	};
	$scope.reject = function(registration) {
		Registration.update({id:registration._id}, {
			status: "rejected"
		}).$promise.then(function(){
			$scope.completed = true;
			$scope.rejected = true;
			
			var message = '<strong>Ok</strong> You successfully rejected the confirmation.';
			Flash.create('info', message, 0, {}, true);
			// window.location.href="/signup"
		}).catch(function(err){
			console.log("Error")
		})
	}
});
